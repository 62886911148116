<template>
  <div class="splash">
    <a href="http://om.nl">
      <img class="logo" src="../assets/img/logo-openbaar-ministerie-en.svg" />
    </a>
    <div class="subtitle">presents</div>
    <div class="intro-center">
      <div class="intro-content">
        <img class="sound-warning" src="../assets/img/sound.png" />
        <div class="link-image">
          <img
            class="MZBlock"
            src="../assets/img/img-intro-moordzaak-block.png"
          />
          <div class="link">
            <button @click="start">
              <img
                class="circle"
                src="../assets/img/img-intro-play-circle.png"
              />
              <img
                class="arrow"
                src="../assets/img/img-intro-play-triangle.png"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="language-switch">
        <div class="language-content">
          <h4>Language</h4>
          <a href="https://de-moordzaak-staging.herokuapp.com/"><span>Dutch</span></a>
          <a class="active"><span>English</span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../data/customEases.js'

export default {
  name: 'Splash',
  methods: {
    start () {
      this.$emit('continue')
    }
  },
  mounted () {
    TweenLite.set('.intro-content', {
      opacity: 0,
      scale: 1.5,
      rotation: 1,
      force3D: true
    })
    TweenLite.to('.intro-content', 0.4, {
      opacity: 1,
      scale: 1,
      rotation: -1,
      force3D: true,
      delay: 1,
      ease: Back.easeOut.config(1.7)
    })

    TweenLite.to('.circle', 0.4, {
      scale: 1.05,
      delay: 2,
      force3D: true,
      ease: 'blink'
    })

    $('.link').hover(
      function () {
        TweenLite.to('.circle', 0.1, { scale: 1.05 })
        TweenLite.to('.arrow', 0.3, {
          x: 30,
          opacity: 0,
          ease: Power1.easeOut
        })
        TweenLite.set('.arrow', { x: -50, opacity: 0, delay: 0.3 })
        TweenLite.to('.arrow', 0.3, {
          x: '-50%',
          y: '-50%',
          opacity: 1,
          delay: 0.3,
          ease: Power1.easeOut
        })
      },
      function () {
        TweenLite.to('.circle', 0.1, { scale: 1 })
      }
    )
  }
}
</script>
