import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

// import Splash from '../views/Splash'
import Intro from '../views/Intro'
import IntroImage from '../views/IntroImage'
import InfoScreen from '../views/InfoScreen'
import Question from '../views/Question'
import Game from '../views/Game'
import Overview from '../views/Overview'
import Timeline from '../views/Timeline'
import Congrats from '../views/Congrats'
import VerdictScreen from '../views/VerdictScreen'

console.log(Intro)
console.log(Congrats)
console.log(Timeline)
const routes = [
  { path: '/', component: Intro, meta: { scrollTop: 0 } },
  // { path: '/intro', component: Intro, meta: { scrollTop: 50 }},
  { path: '/introimage', component: IntroImage, meta: { scrollTop: 10 } },
  { path: '/infoscreen', component: InfoScreen, meta: { scrollTop: 0 } },
  { path: '/question', component: Question, meta: { scrollTop: 0 } },
  { path: '/game', component: Game, meta: { scrollTop: 0 } },
  { path: '/overview', component: Overview, meta: { scrollTop: 0 } },
  { path: '/timeline', component: Timeline, meta: { scrollTop: 0 } },
  { path: '/congrats', component: Congrats, meta: { scrollTop: 0 } },
  { path: '/verdictscreen', component: VerdictScreen, meta: { scrollTop: 0 } },
  { path: '/:pathMatch(.*)', component: Intro, meta: { scrollTop: 0 } }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const debugUrl = '/debugmode'

router.beforeEach((route, redirect, next) => {
  if (route.path === '/question') {
    if (store.state.game.episodes[store.state.game.episode].points && (store.state.game.episodes[store.state.game.episode].answers.length === store.state.game.episodes[store.state.game.episode].actions.length)) {
      return router.push('/overview')
    }
  }
  if (route.path === debugUrl) {
    store.dispatch('ENABLE_DEBUGMODE').then(() => {
      router.push('/timeline')
    })
  } else {
    const firstEpisodeOnly = ['/introimage', '/infoscreen']
    if (store.state.game.episode > 0 && firstEpisodeOnly.indexOf(route.path) !== -1) {
      return router.push('/question')
    }
    next()
  }
})

router.afterEach((route, redirect) => {
  if (window.mobile && route.path !== '/') {
    setTimeout(function () {
      window.scrollTo(0, route.meta.scrollTop)
    }, 500)
  }
})

export default router
