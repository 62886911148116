<template>
    <div class="answers" :class="type" :data-popup="popup">
        <transition-group id="answer-cards" class="answer-cards" name="staggered-fade" tag="ul" :css="false" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <li v-for="(answer, index) in answers" class="answer-card" :class="{'correct': answer.points}" :style="{ width: width() }" :key="index" @click="expand(index)">
                <div class='points'><span>{{ answer.points }}</span> points</div>
                <div class='ac-question'>{{ answer.question }}</div>
                <div class='answer'>You said: <span>{{ answer.answer }}</span></div>
            </li>
        </transition-group>
    </div>
</template>

<script>

export default {
  name: 'Answers',
  props: {
    type: String,
    popup: Boolean,
    mod: Number
  },
  data () {
    return {
      cards: -1
    }
  },
  mounted () {
    this.cards = -1
    const answerCards = document.querySelectorAll('.answer-card')
    for (const card in answerCards) {
      if (typeof answerCards[card] === 'object') {
        this.beforeEnter(answerCards[card])
        this.enter(answerCards[card])
      }
    }
  },
  methods: {
    width () {
      return (this.type === 'inline') ? (100 / (this.cards + 1)) + '%' : '230px'
    },
    beforeEnter (el) {
      TweenMax.set(el, { scale: 1.5, opacity: 0, force3D: true })
    },
    enter (el, done) {
      this.cards++

      const max = 5
      const min = -5
      const degrees = Math.floor(Math.random() * (max - min + 1)) + min
      const y = 0

      TweenMax.to(el, 0.3, { scale: 1, opacity: 1, rotation: degrees, y, onComplete: done, force3D: true }, 0.2)
    },
    leave (el, done) {
      TweenMax.to(el, 0.3, { scale: 0.6, opacity: 0, onComplete: done, force3D: true }, 0.2)
    },
    expand (index) {
      this.$store.dispatch('SHOW_CAROUSEL', { active: index })
    }
  },
  computed: {
    answers: {
      get () {
        return this.$store.state.game.episodes[this.$store.state.game.episode - this.mod].answers
      }
    }
  }
}
</script>
