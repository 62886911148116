<template>
    <div class="game">
        Game<br>
        <router-link to="/">Go back home</router-link> (or use the browser button)<br><br>
    </div>
</template>

<script>
export default {
  name: 'Game'
}
</script>
