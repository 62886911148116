<template>
    <div class="info-screen">
        <div class='intro-text-container'>
                <div class='intro-text-box'>
                <div class='introtext title-02'>
                  <span v-html="episode.introtext"></span>
                </div>
                <router-link to="/question" class="button">OK</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { episodes } from '../data'

export default {
  name: 'InfoScreen',
  data () {
    return {
      episode: {
        introtext: episodes[this.$store.state.game.episode].introtext
      }
    }
  },
  computed: {
    points: {
      get () {
        return this.$store.state.game.points
      }
    },
    action: {
      get () {
        return episodes[this.$store.state.game.episode].actions[this.$store.state.game.action]
      }
    }
  }
}
</script>
