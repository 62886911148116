<template>
  <div class="intro">
    <splash v-if="splash" @continue="startAnimation"></splash>
    <div class="content-wrap" v-show="!splash">
      <div class="content">
        <div class="video-container">
          <video
            style="background: black"
            id="video"
            autoplay
            controls="true"
            width="100%"
            height="100%"
            :src="episode.animation[key].file"
            type="video/mp4"
          ></video>
        </div>
        <div class="intro-animation-image-container">
          <div class="intro-animation-container">
            <div class="back-anim-container">
              <img
                class="intro-animation-image intro-animation-image-back"
                :src="episode.animation[key].file"
              />
            </div>
            <div class="front-anim-container">
              <img
                class="intro-animation-image intro-animation-image-front"
                :src="episode.animation[prevKey].file"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="subtitle-wrap" v-show="!splash">
        <div class="intro-animation-text heading-2 bold">
          {{ episode.animation[key].text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import splash from './Splash'

import { episodes } from '../data/index.js'
import { Howl } from 'howler'

export default {
  name: 'Intro',
  components: { splash },
  data () {
    return {
      splash: this.$store.state.game.episode === 0,
      episode: episodes[this.$store.state.game.episode],
      prevKey: 0,
      key: 0,
      audio: './audio/intro.mp3',
      mobile: false
    }
  },
  mounted () {
    if (
      this.$store.state.game.episodes[this.$store.state.game.episode].answers
        .length === 0
    ) {
      this.$store.dispatch({
        type: 'RESET_EPISODE',
        episode: this.$store.state.game.episode
      })
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.mobile = true
    }
    $('.video-container').css('display', 'none')
    $('.intro-animation-image-container').css('display', 'none')

    if (this.$store.state.game.episode !== 0) {
      this.checkKey()
    }
  },
  methods: {
    startAnimation () {
      this.splash = 0
      this.checkKey()
      TweenLite.set('.intro-animation-container, .intro-animation-text', {
        scale: 1
      })
      TweenLite.set('.intro-animation-text', { opacity: 0 })
      TweenLite.set('.front-anim-container', { scale: 1.15 })
      TweenLite.set('.intro-animation-image-front', { opacity: 0 })
      if (
        this.episode.animation[this.key].audio !== '' &&
        this.episode.animation[this.key].type !== 'video'
      ) {
        this.audio = new Howl({ src: this.audio })
        this.audio.play()
      }
    },
    checkKey () {
      if (this.episode.animation[this.key]) {
        this.checkSource()
      } else {
        this.$router.push('/introimage')
      }
    },
    checkSource () {
      if (this.episode.animation[this.key].type === 'video') {
        TweenLite.set('.intro-animation-text', { opacity: 0 })
        if (this.audio != null) {
          if (this.audio.stop) this.audio.stop()
        }

        $('.video-container').css({ display: 'block' })

        $('.intro-animation-image-container').css('display', 'none')

        if (this.episode.animation[this.key].text) {
          TweenLite.to('.intro-animation-text', 0.5, { opacity: 1, delay: 1 })
        }

        const video_element = document.getElementById('video')

        setTimeout(function () {
          const vid_duration = document.getElementById('video').duration
          TweenLite.to('.intro-animation-text', 0.5, {
            opacity: 0,
            delay: vid_duration - 1
          })
        }, 1000)

        video_element.addEventListener('ended', () => {
          this.key++
          this.checkKey()
        })
      } else {
        this.animateImage()
      }
    },
    animateImage () {
      TweenLite.to('.intro-animation-text', 0.5, { opacity: 1, delay: 0.5 })

      $('.video-container').css('display', 'none')
      $('.intro-animation-image-container').css('display', 'block')

      TweenLite.set('.front-anim-container', { scale: 1.15 })

      TweenLite.set('.back-anim-container', { scale: 1 })
      TweenLite.to('.intro-animation-image-front', 2, {
        opacity: 0,
        onComplete: () => {
          if (this.key > 0) {
            this.prevKey++
          }
          TweenLite.to('.intro-animation-text', 0.5, {
            opacity: 0,
            delay: 1.5
          })
        }
      })

      TweenLite.to('.back-anim-container', 3.5, { delay: 0.2, scale: 1.15 })
      TweenLite.to('.intro-animation-image-front', 0.5, {
        delay: 3.5,
        opacity: 1,
        onComplete: () => {
          this.key++
          this.checkKey()
        }
      })
    }
  },
  beforeUnmount () {
    if (this.audio != null) {
      if (this.audio.stop) this.audio.stop()
    }
  }
}
</script>
