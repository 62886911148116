<template>
  <div class="verdict">
    <div class="content">
      <div class="video-container">
        <img class="image-intro" src="/images/289.jpg" alt="" />
      </div>
    </div>
    <div class="intro-text-container">
      <div class="intro-text-box">
        <div class="introtext title-02">
          The judge followed the demand of the public prosecutor and sentenced the man to 8 years in prison and TBS with compulsory treatment. On appeal, the man was sentenced to 6 years in prison with TBS.
        </div>
        <router-link to="/congrats" class="button">OK</router-link>
        <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import '../data/customEases.js'

export default {
  name: 'verdictScreen',
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>
