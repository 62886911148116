<template>
  <div class="container main-app">
    <div class="bg-wrap">
      <div
        class="main-bg-image"
        :style="{ backgroundImage: 'url(' + thumbnail + ')' }"
      ></div>
      <div class="main-bg-cover"></div>
    </div>

    <div class="desktop-view">
      <transition name="popup" mode="out-in">
        <Carousel v-if="$store.state.carousel.show"></Carousel>
      </transition>

      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>

      <div class="vignette"></div>
    </div>
  </div>
</template>

<script>
import './assets/scss/main.scss'
import { sync } from 'vuex-router-sync'
import store from './store'
import router from './router'

import Carousel from './components/Carousel'

import { episodes } from './data'

sync(store, router)

export default {
  name: 'App',
  components: {
    Carousel
  },
  data: () => ({
    orientation: ''
  }),
  mounted () {
    window.unlockallthethings = () => {
      this.$store.dispatch('ENABLE_DEBUGMODE')
    }
    this.$store.dispatch('UNLOCK_EPISODE', { episode: 0 })
    this.animateBG()
    window.addEventListener('orientationchange', () => {
      this.$nextTick(() => {
        this.checkOrientation()
      })
    })
    this.$nextTick(() => {
      this.checkOrientation()
    })
  },
  computed: {
    thumbnail: {
      get () {
        return episodes[this.$store.state.game.episode].thumbnail
      }
    }
  },
  methods: {
    checkOrientation () {
      this.orientation = ''
      setTimeout(() => {
        this.orientation =
          window.innerHeight < window.innerWidth ? 'landscape' : 'portrait'
      }, 300)
    },
    animateBG () {
      TweenLite.set('.main-bg-cover', { z: 100, opacity: 0.9 })
      TweenLite.set('.main-bg-image', { z: -100 })
      TweenLite.to('.main-bg-image', 60, {
        delay: 20,
        scale: 1.05,
        x: 15,
        y: -15,
        ease: 'bg-wiggle',
        onComplete: () => {
          this.animateBG()
        }
      })
    }
  },
  store,
  router
}
</script>
