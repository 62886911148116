<template>
    <div class="carousel">
        <ul class="container" :style="{left: '-' + x, width: answers.length * 100 + 'vw'}" @click="closeCarousel">
            <li v-for="(answer, index) in answers" :key="`answer_${index}`">
                <div class="card" :class="{'correct': answer.points}">
                    <span class="close" @click="$store.dispatch('HIDE_CAROUSEL')"></span>
                    <div class='action'><span>action {{ index + 1 }} / {{answers.length}}</span></div>
                    <div class='ac-question'>{{ answer.question }}</div>
                    <div class='answer'>You said: <span>{{ answer.answer }}</span><span class='points'><span>{{answer.points}}</span> points</span></div>

                </div>
            </li>
            <div class='arrows'>
                <span :class="{'hidden': $store.state.carousel.active == 0}" class='arrow arrow-left' @click="$store.dispatch('PREVIOUS_CAROUSEL_ITEM')"></span>
                <span :class="{'hidden': $store.state.carousel.active == this.answers.length -1}" class='arrow arrow-right' @click="$store.dispatch('NEXT_CAROUSEL_ITEM')"></span>
            </div>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'Carousel',
  computed: {
    answers: {
      get () {
        return this.$store.state.game.episodes[this.$store.state.game.episode].answers
      }
    },
    x: {
      get () {
        return 100 * this.$store.state.carousel.active + '%'
      }
    }
  },
  methods: {
    closeCarousel (event) {
      const target = event.target || event.srcElement
      if (target.nodeName === 'LI') {
        this.$store.dispatch('HIDE_CAROUSEL')
      }
    }
  }
}
</script>
