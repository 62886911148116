export const episodes = [
  // episode 1
  {
    id: 0,
    title: 'The prosecutor is called awake.',
    thumbnail: './images/81.jpg',
    animation: [
      {
        file: './images/65.jpg',
        type: 'image',
        text: 'A cold and dark February evening 23:34 hrs',
        audio: './audio/ThunderRain.mp3'
      },
      {
        file: './images/71.jpg',
        type: 'image',
        text: 'A lone motorist',
        audio: ''
      },
      {
        file: './images/76.jpg',
        type: 'image',
        text: 'drives home through the woods.',
        audio: ''
      },
      {
        file: './images/81.jpg',
        type: 'image',
        text: 'A man is lying on the road.',
        audio: './audio/ThunderRain.mp3'
      },
      {
        file: './images/86.jpg',
        type: 'image',
        text: 'His face is disfigured beyond recognition.',
        audio: ''
      },
      {
        file: './images/91.jpg',
        type: 'image',
        text: 'Another car?',
        audio: './audio/ThunderRain.mp3'
      },
      {
        file: './images/96.jpg',
        type: 'image',
        text: 'The car is approaching fast.',
        audio: './audio/CarSkid.mp3'
      },
      {
        file: './images/101.jpg',
        type: 'image',
        text: 'The driver can barely avoid the car.',
        audio: './audio/CarSkidBrake.mp3'
      },
      {
        file: './images/106.jpg',
        type: 'image',
        text: 'He calls 112.',
        audio: './audio/ThunderRain.mp3'
      },
      {
        file: './videos/Openingsscene.mp4',
        type: 'video'
      }
    ],
    introImageText:
      'You are on night shift (standby duty) <br> and you are called by the police. An investigation team is being formed.',
    introImage: './images/img-phone.png',
    introtext:
      'You are on night shift (standby duty) and you are called by the police. An investigation team is being formed. You lead the investigation. What will you do?',
    actions: [
      {
        id: 0,
        title: 'Indicate which traces must be secured.',
        answer: true,
        feedback:
          'Yes, it is vital that crime scene traces are secured quickly and accurately.'
      },
      {
        id: 1,
        title: 'Order to hear the driver. ',
        answer: true,
        feedback: 'Yes, everything he has seen could be of interest.'
      },
      {
        id: 2,
        title:
          'Request permission from the examining magistrate (the judge who decides about which investigative means may be used) to wiretap the telephone.',
        answer: false,
        feedback: 'No, there is no telephone you would want to wiretap.'
      },
      {
        id: 3,
        title:
          'Secure the body and order the Netherlands Forensic Institute (NFI) to investigate the cause of death and any traces.  ',
        answer: true,
        feedback:
          'Yes, even though the cause of death may seem obvious, you need to be sure. In addition, the NFI can search for traces of the perpetrator.'
      },
      {
        id: 4,
        title: 'Order the observation of the crime scene.',
        answer: false,
        feedback:
          'No, the perpetrator fled and the crime scene has been fully investigated. There\'s no reason to do anything further there.'
      },
      {
        id: 5,
        title: 'Order a neighbourhood survey in the nearby village.',
        answer: true,
        feedback:
          'Yes, maybe someone saw or heard something at the time of the crime. '
      },
      {
        id: 6,
        title: 'Involve “Crime Watch” [Opsporing Verzocht].',
        answer: false,
        feedback:
          'No, the investigation is still in full swing. Crime Watch can be involved later if necessary if other investigative means have not yielded results. '
      }
    ]
  },
  // episode 2
  {
    id: 1,
    title: 'Crime scene.',
    thumbnail: './images/145.jpg',
    animation: [
      {
        file: './videos/plaatsdelict.mp4',
        type: 'video'
      }
    ],
    introtext:
      'The motorist in the forest was unable to see the licence plate of the car, but he did see that it was a small, red car with a big dent in the hood. The headlights were off. The rear lights remind him of a Renault Twingo. What will you do?',
    actions: [
      {
        id: 0,
        title:
          'You have all garage managers in the area called and asked whether they recognise such a car.',
        answer: false,
        feedback:
          'No, it is doubtful whether this yields any results and this is how information comes out that you would rather keep within the investigation for the time being. '
      },
      {
        id: 1,
        title:
          'You request the police to search the internal police system for a small red car with damage to the hood.',
        answer: true,
        feedback: 'Yes, this brings up three possible cars.'
      }
    ]
  },
  // episode 3
  {
    id: 2,
    title: 'The Netherlands Forensic Institute',
    thumbnail: './images/156.jpg',
    animation: [
      {
        file: './images/156.jpg',
        type: 'image',
        text: 'The Netherlands Forensic Institute.',
        audio: ''
      },
      {
        file: './videos/forensisch.mp4',
        type: 'video'
      }
    ],
    introtext:
      'The Netherlands Forensic Institute (NFI) reports the cause of death: excessive force on the head with a hollow or round object. What will you do with this information? ',
    actions: [
      {
        id: 0,
        title:
          'You publicise this in a press release. Maybe someone knows the weapon.',
        answer: false,
        feedback:
          'No, this way you give away information that is only known to the perpetrator.'
      },
      {
        id: 1,
        title: 'You keep this a secret.',
        answer: true,
        feedback:
          'Yes, this is offender information and can be important for the evidence. '
      },
      {
        id: 2,
        title: 'You release the body.',
        answer: true,
        feedback:
          'Yes, when the NFI investigation has been completed, the public prosecutor will decide that the body can be handed over to the family.'
      }
    ]
  },
  // episode 4
  {
    id: 3,
    title: 'Evidence?',
    thumbnail: './images/169.jpg',
    animation: [
      {
        file: './images/169.jpg',
        type: 'image',
        text: 'Evidence?',
        audio: ''
      }
    ],
    introtext:
      'A few days later, the theft of a car is reported. It is one of the three cars that have come forward in the investigation. The car is found floating in a ditch. What will you do?',
    actions: [
      {
        id: 0,
        title: 'You have the car destroyed.',
        answer: false,
        feedback: 'No, you might destroy traces.'
      },
      {
        id: 1,
        title:
          'You seize the car and order it to be examined for traces.',
        answer: true,
        feedback:
          'Yes, it is best to search for traces as soon as possible.'
      },

      {
        id: 2,
        title: 'You seize the car and have it stored somewhere.',
        answer: false,
        feedback:
          'No, if you decide to keep the car for investigation, it is better to investigate it right away.'
      }
    ]
  },
  // episode 5
  {
    id: 4,
    title: 'The public prosecutor is in charge of the investigation.',
    thumbnail: './images/183.jpg',
    animation: [
      {
        file: './images/183.jpg',
        type: 'image',
        text: 'The public prosecutor is in charge of the investigation.',
        audio: ''
      }
    ],
    introtext: 'What will you do to find out the identity of the victim?',
    actions: [
      {
        id: 0,
        title: 'You have DNA taken.',
        answer: false,
        feedback:
          'At this stage of the investigation it is not necessary to do DNA testing to establish the identity.'
      },
      {
        id: 1,
        title:
          'You let the police check whether someone has been reported missing who matches the description.',
        answer: true,
        feedback:
          'Yes, this will often help in cases like this.'
      },
      {
        id: 2,
        title:
          'You use TV programs such as Crime Watch where you call on people to report if they have seen something. ',
        answer: false,
        feedback:
          'No, sometimes that does happen, but in this case there are still plenty of other possibilities to investigate further. '
      },
      {
        id: 3,
        title: 'You have fingerprints examined.',
        answer: true,
        feedback:
          'Yes, and so it is revealed that it concerns a man who lives with the Salvation Army. He was last seen at home that evening between eight and nine o\'clock.'
      }
    ]
  },
  // episode 6
  {
    id: 5,
    title: 'Consultation with the police.',
    thumbnail: './images/202.jpg',
    animation: [
      {
        file: './images/202.jpg',
        type: 'image',
        text: 'Consultation with the police. ',
        audio: ''
      }
    ],
    introtext: 'Now that the victim has been identified, what will you do?',
    actions: [
      {
        id: 0,
        title:
          'You disclose the identity and hope that people who know him will come forward.',
        answer: false,
        feedback:
          'No, there are all kinds of other ways to advance in the investigation now. '
      },
      {
        id: 1,
        title:
          'You let the police trace down the family so they can bury him.',
        answer: true,
        feedback:
          'Yes, that too is part of the job of the police. And maybe the family has some important information. '
      },
      {
        id: 1,
        title:
          'You let the police investigate his personal environment and have friends and acquaintances examined.',
        answer: true,
        feedback: 'Yes, this may provide new clues. '
      }
    ]
  },
  // episode 7
  {
    id: 6,
    title: 'A breakthrough! ',
    thumbnail: './images/218.jpg',
    animation: [
      {
        file: './images/218.jpg',
        type: 'image',
        text: 'A breakthrough! ',
        audio: ''
      }
    ],
    introtext:
      'One of the victim\'s acquaintances is also the owner of the red car found in the ditch. In addition, this man regularly goes to the forest concerned with his dog and has committed violence before. What will you do?',
    actions: [
      {
        id: 0,
        title:
          'You consider the man to be a suspect and ask the examining magistrate to wiretap his phone. ',
        answer: true,
        feedback:
          'Yes, the suspect may be talking about the crime over the phone. '
      },
      {
        id: 1,
        title: 'You close the investigation and summon the man.',
        answer: false,
        feedback:
          'No, way too early. You have a suspicion but no hard evidence yet. '
      },
      {
        id: 2,
        title: 'You have the man arrested.',
        answer: false,
        feedback:
          'No, way too early. You have a suspicion but no hard evidence yet.'
      },
      {
        id: 3,
        title:
          'You involve TV investigation programs where you call on people to report if they have seen something suspicious.',
        answer: true,
        feedback:
          'Yes, now is a good time to ask if people have seen anything suspicious.'
      }
    ]
  },
  // episode 8
  {
    id: 7,
    title: 'The well-known TV program is involved.',
    thumbnail: './images/231.jpg',
    animation: [
      {
        file: './images/231.jpg',
        type: 'image',
        text: 'The well-known TV program is involved.',
        audio: ''
      },
      {
        file: './videos/opsporing.mp4',
        type: 'video'
      }
    ],
    introtext:
      'Tips are coming in. Someone names the suspect and knows about the damaged car. The suspect himself also calls and says that the victim\'s best friend did it. Here he tells details that only the perpetrator can know. What will you do? ',
    actions: [
      {
        id: 0,
        title: 'You arrest the suspect and confront him with this. ',
        answer: false,
        feedback:
          'No, the case is getting stronger, but there is still too little evidence to arrest the suspect. The investigation must continue in order to find more evidence. '
      },
      {
        id: 1,
        title:
          'You do not have the suspect arrested, but you do instruct the police to record and store all this info accurately. ',
        answer: true,
        feedback: 'Yes, you know this will be very important.'
      }
    ]
  },
  // episode 9
  {
    id: 8,
    title: 'Witnesses report to the police. ',
    thumbnail: './images/242.jpg',
    animation: [
      {
        file: './images/242.jpg',
        type: 'image',
        text: 'Witnesses report to the police. ',
        audio: ''
      }
    ],
    introtext:
      'Two acquaintances of both the victim and the suspect who did not want to say anything earlier report to the police station. Both are known as alcoholics. They say that the suspect was with them on the night of the murder and left at a quarter to ten with an iron bar. Later he came back with bloodied hands and said that he had beaten the rapist of his children to death. The next day he thoroughly cleaned his car. What will you do?',
    actions: [
      {
        id: 0,
        title: 'You have the suspect arrested. ',
        answer: true,
        feedback:
          'Yes, there is now sufficient evidence to not only arrest the man, but also to bring him before the examining magistrate who will decide whether the man should be held in custody longer after three days with the police. If there is too little evidence, he will be released and there is a risk of flight. '
      },
      {
        id: 1,
        title: 'You have him put under observation.',
        answer: false,
        feedback:
          'No, you now have enough evidence to arrest him. Observation would be a waste. '
      },
      {
        id: 2,
        title: 'You will look for more supporting evidence for this statement.',
        answer: true,
        feedback:
          'Yes, this is necessary. The suspect\'s lawyer finds the statement of the two acquaintances too unreliable because the men are addicted to alcohol.'
      }
    ]
  },
  // episode 10
  {
    id: 9,
    title: 'The hearing.',
    thumbnail: './images/255.jpg',
    animation: [
      {
        file: './videos/aanhoudingen.mp4',
        type: 'video'
      },
      {
        file: './images/255.jpg',
        type: 'image',
        text: 'The hearing. ',
        audio: ''
      },
      {
        file: './videos/rechtzaak.mp4',
        type: 'video'
      }
    ],
    introtext:
      'You summoned the accused, so he has to appear in court. What evidence do you provide against him?',
    actions: [
      {
        id: 0,
        title: 'The statement of the two acquaintances. ',
        answer: true,
        feedback:
          'Yes, the lawyer may say that the statements are not reliable, but the men came up with the story on their own initiative.  '
      },
      {
        id: 1,
        title: 'The data from the technical examination of the car. ',
        answer: true,
        feedback:
          'Yes, and this in combination with the driver\'s statement. '
      },
      {
        id: 2,
        title: 'The statement of the accused himself. ',
        answer: true,
        feedback:
          'Yes, whereby the accused does not have to answer the questions that are put to him. '
      },
      {
        id: 3,
        title: 'The wiretapped telephone calls.',
        answer: true,
        feedback:
          'Yes, this can be both incriminating and exculpatory material. In the wiretaps concerned the accused tells an acquaintance that "there is a reward on my head" and he also confesses to having beaten someone with an iron bar. '
      }
    ]
  },
  // episode 11
  {
    id: 10,
    title: 'The Pieter Baan Centre investigates.',
    thumbnail: './images/270.jpg',
    animation: [
      {
        file: './images/270.jpg',
        type: 'image',
        text: 'The Pieter Baan Centre investigates.',
        audio: ''
      }
    ],
    introtext:
      'You have asked the court to have the man psychologically examined at the Pieter Baan Centre. The aim is to find out whether the man is fully or partially mentally insane. The Pieter Baan Centre determines that the man has a personality disorder and can be held partially accountable for his actions. The maximum sentence for murder is life imprisonment. Which punishment do you think is appropriate? ',
    actions: [
      {
        id: 0,
        title: 'Community service with mandatory probation visits. ',
        answer: false,
        feedback: 'No, this is too low a sentence according to the Public Prosecution Service.'
      },
      {
        id: 1,
        title: 'A prison sentence of six years with a hospital order (TBS) with compulsory treatment.',
        answer: true,
        feedback:
          'Yes, this is possible. On appeal, the accused received this sentence from the Court of Appeal.'
      },
      {
        id: 2,
        title: 'An eight-year prison sentence with TBS. ',
        answer: true,
        feedback:
          'Yes, the man should not only be put in prision but also be treated for his psychological problems. '
      },
      {
        id: 3,
        title: 'A 35-year prison sentence ',
        answer: false,
        feedback:
          'No, besides life imprisonment, 30 years is the highest prison sentence a person can receive.'
      },
      {
        id: 4,
        title: 'A life sentence',
        answer: false,
        feedback:
          'No, a life sentence is not often demanded. In this case it matters that the man has a personality disorder.'
      }
    ]
  }
  // episode 12
  // {
  //     id: 11,
  //     title: 'De rechtzaak',
  //     thumbnail: './images/289.jpg',
  //     animation: [
  //         {
  //             file: './images/289.jpg',
  //             type: 'image',
  //             text: 'De rechtzaak'
  //         }
  //     ],
  //     introtext: 'De rechtzaak',
  //     actions: [
  //         {
  //             id: 0,
  //             title: 'Moet de verdachten veroordeeld worden?',
  //             answer: true,
  //             feedback: 'Ja'
  //         }
  //     ]
  // }
]

export const endtexts = [
  {
    id: 0,
    header: 'Unfortunately',
    text: 'Too bad, I\'m afraid you did not get the accused behind bars, but if you still want to know more about the work of the public prosecutor, take a look at our site on <a href="https://www.om.nl" target="_BLANK">www.om.nl</a>',
    video: './videos/Slechtescore.mp4'
  },
  {
    id: 1,
    header: 'Congratulations',
    text: 'You have a certain talent for detective work, but you still make a mistake every now and then. And that can ensure that your accused is acquitted at trial or that you do not have enough evidence to go to court. If you want to know more about the work of the public prosecutor, visit <a href="https://www.om.nl" target="_BLANK">www.om.nl</a>',
    video: './videos/Matigescore.mp4'
  },
  {
    id: 2,
    header: 'Congratulations',
    text: 'Well done. If you were in charge of the investigation, criminals would not be safe in our country. You could be a good public prosecutor. Do you want to know more about this work? Then look at <a href="https://www.om.nl" target="_BLANK">www.om.nl</a>',
    video: './videos/Goedescore.mp4'
  }
]
