import { createStore } from 'vuex'

// Import app data and config
import { episodes } from '../data'
// import { config } from '../data/config.js'

// Initial state
const state = {
  debugmode: 0,
  game: {
    episodes: {},
    episode: 0,
    action: 0
  },
  carousel: {
    show: false,
    active: 0
  }
}

// Populate state episodes
populateEpisodes()
function populateEpisodes () {
  for (const episode in episodes) {
    state.game.episodes[episode] = episodes[episode]
    state.game.episodes[episode].locked = true
    resetEpisode(episode)
  }
}

function resetEpisode (episode) {
  state.game.episodes[episode].points = 0
  state.game.episodes[episode].answers = []
  state.game.action = 0
}

export default createStore({
  state: state,
  mutations: {
    ENABLE_DEBUGMODE: (state) => {
      state.debugmode = 1
    },
    RESET_GAME: (state) => {
      populateEpisodes()
      state.game.episode = 0
    },
    ADD_ANSWER: (state, payload) => {
      state.game.episodes[state.game.episode].answers.push({
        episode: payload.answer.episode,
        action: payload.answer.action,
        answer: payload.answer.answer,
        points: payload.answer.points,
        question: payload.answer.question
      })
    },
    RESET_EPISODE: (state, { episode }) => {
      resetEpisode(episode)
    },
    CHANGE_EPISODE: (state, { episode }) => {
      state.game.episode = episode
    },
    UNLOCK_EPISODE: (state, { episode }) => {
      state.game.episodes[episode].locked = false
    },
    LOCK_EPISODE: (state, { episode }) => {
      state.game.episodes[episode].locked = true
    },
    NEXT_ACTION: (state) => {
      state.game.action++
    },
    PREVIOUS_ACTION: (state) => {
      state.game.action--
    },
    SET_ACTION: (state, { action }) => {
      state.game.action = action
    },
    CHANGE_POINTS: (state, { points }) => {
      if (points < 0) {
        state.game.episodes[state.game.episode].points -= Math.abs(points)
      } else {
        state.game.episodes[state.game.episode].points += points
      }
    },
    SHOW_CAROUSEL: (state) => {
      state.carousel.show = true
    },
    HIDE_CAROUSEL: (state) => {
      state.carousel.show = false
    },
    NEXT_CAROUSEL_ITEM: (state) => {
      state.carousel.active++
    },
    PREVIOUS_CAROUSEL_ITEM: (state) => {
      state.carousel.active--
    }
  },
  actions: {
    ENABLE_DEBUGMODE: ({ commit }) => {
      return new Promise((resolve, reject) => {
        for (const episode in state.game.episodes) {
          commit('UNLOCK_EPISODE', { episode })
        }
        commit('ENABLE_DEBUGMODE')
        resolve()
      })
    },
    RESET_GAME: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('RESET_GAME')
        resolve()
      })
    },
    ADD_ANSWER: ({ commit }, { answer }) => {
      commit('ADD_ANSWER', { answer })
    },
    RESET_EPISODE: ({ commit }, { episode }) => {
      if (episodes[episode]) {
        commit('RESET_EPISODE', { episode })
      }
    },
    CHANGE_EPISODE: ({ commit }, { episode }) => {
      if (episodes[episode]) {
        commit('CHANGE_EPISODE', { episode })
      }
    },
    UNLOCK_EPISODE: ({ commit }, { episode }) => {
      return new Promise((resolve, reject) => {
        if (episodes[episode]) {
          commit('UNLOCK_EPISODE', { episode })
        }
        resolve()
      })
    },
    LOCK_EPISODE: ({ commit }, { episode }) => {
      return new Promise((resolve, reject) => {
        if (episodes[episode]) {
          commit('LOCK_EPISODE', { episode })
        }
        resolve()
      })
    },
    NEXT_ACTION: ({ commit }) => {
      return new Promise((resolve, reject) => {
        if (state.game.action < episodes[state.game.episode].actions.length - 1) {
          commit('NEXT_ACTION')
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },
    PREVIOUS_ACTION: ({ commit }) => {
      if (state.game.action) {
        commit('PREVIOUS_ACTION')
      }
    },
    SET_ACTION: ({ commit }, { action }) => {
      if (episodes[state.game.episode].actions[action]) {
        commit('SET_ACTION')
      }
    },
    CHANGE_POINTS: ({ commit }, { points }) => {
      return new Promise((resolve, reject) => {
        commit('CHANGE_POINTS', { points })
        resolve()
      })
    },
    SHOW_CAROUSEL: ({ commit }, { active }) => {
      active = active || 0
      state.carousel.active = active
      commit('SHOW_CAROUSEL')
    },
    HIDE_CAROUSEL: ({ commit }) => {
      commit('HIDE_CAROUSEL')
    },
    NEXT_CAROUSEL_ITEM: ({ commit }) => {
      if (state.carousel.active < state.game.episodes[state.game.episode].answers.length - 1) {
        commit('NEXT_CAROUSEL_ITEM')
      }
    },
    PREVIOUS_CAROUSEL_ITEM: ({ commit }) => {
      if (state.carousel.active) {
        commit('PREVIOUS_CAROUSEL_ITEM')
      }
    }
  },
  modules: {
  }
})
