<template>
  <div class="question">
    <aside
      v-if="questionToggle != null"
      class="questionToggle"
      :class="{ toggled: questionToggle }"
      @click="toggleQuestion"
    ></aside>
    <transition @enter="slideinQuestion" @leave="slideoutQuestion">
      <div
        class="introtext-container"
        :class="{
          floating: questionToggle != null,
        }"
        v-if="questionToggle || questionToggle != false"
      >
        <div class="introtext" @click="setupQuestionToggle">
          <div class="intro-question-title" v-if="questionToggle != null">
            QUESTION
          </div>
          <span v-html="episode.introtext"></span>
        </div>
      </div>
    </transition>

    <div class="question-feedback-holder">
      <div class="question-feedback">
        <div
          class="feedback-container"
          v-show="showFeedback"
          :class="{ correct: correct, show: showFeedback }"
        >
          <div class="feedback" v-if="action">
            <div class="title">
              {{ correct ? "Right" : "Wrong" }} decision!
            </div>
            <div class="feedback-text">
              {{ action.feedback }}
            </div>
            <div class="button-container">
              <a class="button" @click="nextAction">OK</a>
            </div>
          </div>
        </div>

        <transition name="fadein">
          <div class="question-container">
            <div class="question-box" v-if="action">
              <div class="question-action-header">
                actie {{ action.id + 1 }} / {{ total }}
              </div>
              <div class="question-action-title">
                {{ action.title }}
              </div>
              <div class="question-action-answers">
                <div
                  @click="processAction(false)"
                  class="answer answer-left"
                  :class="{ active: answer === false }"
                >
                  {{ config.false }}
                </div>
                <div
                  @click="processAction(true)"
                  class="answer answer-right"
                  :class="{ active: answer === true }"
                >
                  {{ config.true }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <Answers :type="'compact'" :mod="0"></Answers>

    <div class="points-container" v-if="questionToggle != null">
      <div class="explosion-container">
        <div class="anim-container">
          <div class="glow"></div>

          <div class="bolts">
            <img src="../assets/img/bolt.png" class="bolt bolt1" />
            <img src="../assets/img/bolt.png" class="bolt bolt2" />
            <img src="../assets/img/bolt.png" class="bolt bolt3" />
            <img src="../assets/img/bolt.png" class="bolt bolt4" />
            <img src="../assets/img/bolt.png" class="bolt bolt5" />
          </div>

          <div class="halos">
            <img src="../assets/img/halo.png" class="halo halo1" />
            <img src="../assets/img/halo.png" class="halo halo2" />
            <img src="../assets/img/halo.png" class="halo halo3" />
          </div>

          <div class="bricks">
            <img src="../assets/img/brick-small.png" class="brick brick1" />
            <img src="../assets/img/brick-large.png" class="brick brick2" />
          </div>
        </div>
      </div>
      {{ points }}<span> punten</span>
    </div>
  </div>
</template>

<script>
import Answers from '../components/Answers'
import { config } from '../data/config'

export default {
  name: 'Question',
  data () {
    return {
      episode: this.$store.state.game.episodes[this.$store.state.game.episode],
      total:
        this.$store.state.game.episodes[this.$store.state.game.episode].actions
          .length,
      intro:
        this.$store.state.game.episodes[this.$store.state.game.episode].intro,
      feedback:
        this.$store.state.game.episodes[this.$store.state.game.episode]
          .feedback,
      showFeedback: false,
      correct: false,
      config,
      top: 0,
      answer: null,
      questionToggle: null
    }
  },
  mounted () {
    TweenLite.set('.explosion-container', { scale: 0.75 })
  },
  components: {
    Answers
  },
  computed: {
    points: {
      get () {
        let points = 0
        for (const i in this.$store.state.game.episodes) {
          const episode = this.$store.state.game.episodes[i]
          points += episode.points
        }
        return points
      }
    },
    action: {
      get () {
        return this.episode.actions[this.$store.state.game.action]
      }
    }
  },
  methods: {
    slideoutQuestion () {
      TweenLite.to('.introtext', 0.4, {
        y: -300,
        opacity: 0,
        force3D: true,
        onComplete: () => {
          this.questionToggle = false
        }
      })
    },
    slideinQuestion () {
      TweenLite.fromTo(
        '.introtext',
        0.4,
        { y: -300, opacity: 0 },
        { y: '-50%', opacity: 1, force3D: true }
      )
    },
    toggleQuestion () {
      if (this.questionToggle) {
        this.setupQuestionToggle()
      } else {
        this.questionToggle = !this.questionToggle
      }
    },
    setupQuestionToggle () {
      TweenLite.to('.introtext', 0.4, {
        y: -300,
        opacity: 0,
        force3D: true,
        onComplete: () => {
          this.questionToggle = false
        }
      })
    },
    processAction (answer) {
      // check if awnser is already filed in
      if (this.answer !== null) return

      this.answer = answer
      this.showFeedback = true
      const i =
        answer === this.action.answer ? config.increment : config.decrement

      this.$store.dispatch('CHANGE_POINTS', { points: i }).then(() => {
        answer = {
          action: this.$store.state.game.action,
          answer: config[answer],
          question: this.action.title,
          episode: this.$store.state.game.episode,
          points: i
        }
        this.$store.dispatch('ADD_ANSWER', { answer })
        TweenLite.set('.feedback-container', {
          minHeight: document.querySelector('.question-container').offsetHeight + 'px',
          maxHeight:
            document.querySelector('.question-container').offsetHeight + 'px',
          opacity: 0,
          force3D: true
        })
        TweenLite.to('.feedback-container', 0.3, {
          maxHeight: '100vh',
          opacity: 1,
          delay: 0.5,
          force3D: true
        })
        this.correct = answer.points
        if (this.correct !== 0) {
          this.explode()
        }
      })
    },
    explode () {
      var animspeed = 0.45

      TweenLite.set('.anim-container', { opacity: 0 })

      TweenLite.set('.bolts', { rotation: 0 })
      TweenLite.set('.halos', { rotation: 0 })

      TweenLite.set('.bolt1', { rotation: 72, x: 40, y: 70, scale: 0.1 })
      TweenLite.set('.bolt2', { rotation: 144, x: 70, y: 50, scale: 0.1 })
      TweenLite.set('.bolt3', { rotation: 216, x: 70, y: 50, scale: 0.1 })
      TweenLite.set('.bolt4', { rotation: 288, x: 40, y: 30, scale: 0.1 })
      TweenLite.set('.bolt5', { rotation: 0, x: 20, y: -20, scale: 0.1 })

      TweenLite.set('.halo1', { rotation: 0, x: 50, y: 60, scale: 0.1 })
      TweenLite.set('.halo2', { rotation: 30, x: 60, y: 0, scale: 0.1 })
      TweenLite.set('.halo3', { rotation: 240, x: 40, y: -70, scale: 0.1 })

      TweenLite.set('.brick1', { x: 50, y: 50, scale: 0.1 })
      TweenLite.set('.brick2', { x: 50, y: -50, scale: 0.1 })

      TweenLite.to('.anim-container', animspeed / 2, { opacity: 1 })

      TweenLite.to('.bolts', animspeed, { rotation: 25 })
      TweenLite.to('.halos', animspeed, { rotation: -25 })

      TweenLite.to('.bolt1', animspeed, { x: 30, y: 10, scale: 1 })
      TweenLite.to('.bolt2', animspeed, { x: 180, y: -20, scale: 1 })
      TweenLite.to('.bolt3', animspeed, { x: 140, y: 100, scale: 1 })
      TweenLite.to('.bolt4', animspeed, { x: 20, y: 100, scale: 1 })
      TweenLite.to('.bolt5', animspeed, { x: -50, y: -20, scale: 1 })

      TweenLite.to('.halo1', animspeed, { x: 50, y: 0, scale: 1 })
      TweenLite.to('.halo2', animspeed, { x: 100, y: -40, scale: 1 })
      TweenLite.to('.halo3', animspeed, { x: -20, y: 0, scale: 1.5 })

      TweenLite.to('.brick1', animspeed, {
        rotation: 50,
        x: 200,
        y: -100,
        scale: 1
      })
      TweenLite.to('.brick2', animspeed, {
        rotation: 50,
        x: 200,
        y: 0,
        scale: 1
      })

      TweenLite.to('.anim-container', animspeed, {
        opacity: 0,
        delay: animspeed / 2
      })
    },
    nextAction () {
      this.answer = null
      this.$store.dispatch('NEXT_ACTION').then((response) => {
        TweenLite.to('.question-action-title', 0.5, { delay: 0.3, opacity: 1 })
        if (!response) {
          this.$router.push('/overview')
        }
      })
      TweenLite.to('.question-action-title', 0.1, { opacity: 0 })
      TweenLite.to('.feedback-container', 0.3, {
        maxHeight:
            document.querySelector('.question-container').offsetHeight + 'px',
        opacity: 0,
        force3D: true,
        onComplete: () => {
          this.showFeedback = false
        }
      })
    }
  }
}
</script>
